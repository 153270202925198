.contactUs {
  padding: 6rem 0rem;
  width: 100%;
  position: relative;
  /* background-color: #ad974f47; */
  background: rgb(34,25,0);
background: radial-gradient(circle, rgba(34,25,0,1) 0%, rgba(0,0,0,1) 100%);
}

.contactUs .overlay {
  position: absolute;
  left: 0;
  bottom: 77%;
  width: 120%;
  height: 270px;
  background: #23221f;
  /* transform-origin: top left; */
  transform: rotate(-8deg);
  z-index: 1;
  /* box-shadow: 0 0 1px 0 #FFFFFF inset, 0 0 1px 0 #FFFFFF; */
  border-top: 1px solid #8e793e;
}

.contactUs-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 1;
}


/* .contactUs-title span {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8e793e;
  line-height: 0.75;
}

.contactUs-title h2 {
  font-size: 60px;
  font-weight: 200;
  color: #ffffff;
  line-height: 1.1;
  margin-top: 3px;
}

.contactUs-title h2 span {
  font-size: 60px;
  color: #ffffff;
  line-height: 1.1;
  margin-top: 3px;
  font-weight: 600;
} */

.contactUs-box {
  display: flex;
  align-items: center;
  gap: 2rem;
  background-color: #ad974f47;
  padding: 5rem;
  border-radius: 5px;
}
.contactUs-box form {
  width: 100%;
}

.form-message p{
  color: white;
}
/* .contactUs-left {
  color: white;
  width: 50%;
}
.contactUs-left p{
  color: white;
  width: 80%;
} */

.contact-title {
  margin-bottom: 35px;
}

.contact-title span {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8e793e;
  line-height: 0.75;
}

.contact-title h2 {
  font-size: 50px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.1;

  margin-top: 3px;
}
.contact-title h2 span {
  font-size: 60px;
  color: #ffffff;
  line-height: 1.1;
  margin-top: 3px;
  font-weight: 600;
}

/* .contactUs-left p svg {
  font-size: 30px;
  color: #8e793e;
  margin-right: 20px;
} */

/* .contactUs-right {
  color: black;
  width: 50%;
  border-radius: 5px;
  background: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 4rem;
} */
/* .contactUs-right form{
  width: 100%;
} */

.contact-form-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 2rem;
}

.input-box label {
  display: block;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  margin-top: 12px;
  margin-bottom: 10px;
}
.input-box input {
  width: 100%;
  color: white;
  outline: none;
  background-color: #62615e;
  margin-bottom: 5px;
  border-style: solid;
  border: none;
  padding: 10px;
  border-radius: 5px;
}
.input-box textarea {
  width: 100%;
  color: white;
  outline: none;
  background-color: #62615e;
  margin-bottom: 20px;
  border-style: solid;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.contact-form-box-button {
  /* margin-top: 20px; */
  display: flex;
  justify-content: space-between;
}
.contact-form-box-button p {
  width: 60%;
  color: #6c757d;
}

.contactUs-box form button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  height: 100%;
  border: none;
  padding: 0;
  border-radius: 5px;
}
.contactUs-box form button p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background: #8e793e;
  color: white;
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.contactUs-box form button p:hover:before {
  width: 70%;
}
.contactUs-box form button p:before {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 0px;
  height: 100%;
  content: "";
  background: #ad974f;
  border-radius: 5px;
  z-index: -1;
  transition: all 200ms ease;
}

@media (min-width: 1200px) {
  .contactUs-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {
  .contactUs-box {
    display: block;
    padding: 4rem 2rem;
  }

  /* .contactUs-left {
    width: 100%;
    padding: 0 15px;
  } */
  /* .contactUs-left p {
    width: 100%;
  } */

  .contactUs-main {
    padding: 0;
  }
/* 
  .contactUs-right {
    width: 100%;
    border-radius: 0px;
    padding: 4rem 2rem;
    margin-top: 4rem;
  } */

  .contact-title{
    padding: 0 15px;
  }

  .contact-title span {
    font-size: 18px;
}

  .contact-title h2 {
    font-size: 35px;
  }

  .contact-form-box{
    display: block;
  }

  .contact-form-box-button{
    display: block;
  }

  .contact-form-box-button p {
    width: 100%;
  }

  .contactUs-box form button {
    width: 100%;
  }
}
