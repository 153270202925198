.banner-heading {
  position: absolute;
  z-index: 999;
  top: 45%;
  width: 100%;
  text-align: center;
}

.banner-heading span {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  line-height: 0.75;
}
.banner-heading h1 {
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 700;
  color: #ad974f;
  line-height: 1.2;
  margin-top: -9px;
}
.banner-heading p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: -6px;
}
.banner-heading a {
  color: #fff;
  text-decoration: none;
}

.banner-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-image img {
  width: 20% !important;
  object-fit: cover;
  height: auto !important;
  padding: 10px;
  border: 1px solid #8e793e;
}
.banner-heading .banner-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.banner-heading .banner-button p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 190px;
  height: 50px;
  border-radius: 5px;
  background: #8e793e;
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-size: 20px;
}
.banner-heading .banner-button p svg{
  font-size: 20px;
 margin-right: 10px;
}
.banner-heading .banner-button p:before {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 0px;
  height: 100%;
  content: "";
  background: #ad974f;
  border-radius: 5px;
  z-index: -1;
  transition: all 200ms ease;
}
.banner-heading .banner-button p:hover:before {
  width: 130px;
}

.banner img {
  height: 91vh;
  width: 100%;
  object-fit: cover;
  filter: brightness(60%);
}
.banner {
  margin-top: 80px;
}

@media only screen and (max-width: 1200px) {
  .banner img {
    object-fit: cover;
  }

  .banner-heading {
    max-width: 1170px;
  }
}

@media only screen and (max-width: 680px) {
  .banner-heading {
    position: absolute;
    z-index: 999;
    /* display: flex; */
    top: 35%;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
  }

  .banner-heading h1 {
    margin-top: 20px;
    font-size: 60px;
  }

  .banner img {
    width: 100%;
    height: 92vh;
    object-fit: cover;
  }

  .banner-image img {
    width: 40% !important;
}

.banner-heading .banner-button p {
  margin: 0;
  width: 210px;
  height: 55px;
}
}
