.Footer {
  border-top: 1px solid #ad974f;
  padding: 6rem 0rem;
  padding-bottom: 0;
  width: 100%;
  background-color: black;
}

.footer-main {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.footer-logo {
  text-align: center;
  color: white;
  margin-bottom: 20px;
}
.footer-logo img{
  width: 20%;
}
.footer-menu {
  width: 100%;
}

.footer-menu ul {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-menu ul li {
  display: flex;
  align-items: center;
}

.footer-menu ul li a {
  text-decoration: none;
  color: #8e793e;
  padding: 10px;
}
.footer-menu ul li a:hover {
  color: white;
}

.footer-search {
  margin-top: 50px;
  display: flex;
  align-items: center;
  position: relative;
}

.footer-search input {
  width: 100%;
  border-radius: 5px;
  padding: 15px;
  padding-left: 30px;
  outline: none;
  background-color: #121212;
  color: white;
  border: 1px solid #8e793e;
}
.footer-search p {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 20%;
  border-radius: 0px 5px 5px 0px;
  background: #8e793e;
  color: white;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
}
.footer-search p:before {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 0;
  height: 100%;
  content: "";
  background: #ad974f;
  border-radius: 5px;
  z-index: -1;
  transition: all 200ms ease;
}
.footer-search p:hover:before {
  width: 50%;
}

.footer-contact{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-contact .call-number p a:hover{
  color: #fff;
}

.footer-contact .instagram a{
  display: flex;
  align-items: center;
  text-decoration: none;
    margin: 0;
    color: #AD974F;
    font-size: 25px;
    font-weight: 700;
}
.footer-contact .instagram a:hover{
    color: #fff;
}
.footer-contact .instagram a p{
  margin: 0;
}

.footer-copyright {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.493);
}
.footer-copyright p {
  padding-left: 20px;
}
.footer-copyright p a {
  color: rgba(255, 255, 255, 0.493);
  text-decoration: none;
}

@media (min-width: 1200px) {
  .footer-main {
    max-width: 600px;
  }
}

@media only screen and (max-width: 680px) {

  .footer-logo img {
    width: 40%;
}
  .footer-copyright {
    display: block;
    text-align: center;
    padding-bottom: 10px;
  }
  .footer-copyright p{
    font-size: 12px;
    margin: 0;
    padding-bottom: 5px;
  }

  .footer-contact{
    display: block;
  }
  .footer-contact .call-number{
    display: flex !important;
  }

  .footer-contact .instagram a {
    justify-content: center;
    padding: 20px 0px;
  }
}
