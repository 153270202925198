.aboutUs {
  /* padding: 6rem 0rem; */
  width: 100%;
  position: relative;
}

.about-main {
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.about-gym {
  position: absolute;
  rotate: 90deg;
  left: -15%;
  top: 18%;
  color: #fffbee1f;
  font-size: 230px;
  font-weight: 900;
  z-index: 0;
}

.about-left {
  z-index: 3;
  width: 50%;
  padding: 0px 15px;
  margin-left: 10%;
}

.about-title span {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8e793e;
  line-height: 0.75;
}
.about-title h2 {
  /* text-transform: uppercase; */
  font-size: 50px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.1;
  margin-top: 3px;
}

.about-detail p {
  font-size: 16px;
  /* font-weight: 200; */
  color: #ffffff;
  line-height: 1.75;
  margin-top: 35px;
  color: gray;
}

.about-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.about-button a {
  text-decoration: none;
}
.about-button p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 190px;
  height: 43px;
  border-radius: 5px;
  background: #8e793e;
  color: white;
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.about-button p:hover:before {
  width: 130px;
}
.about-button p:before {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 0px;
  height: 100%;
  content: "";
  background: #ad974f;
  border-radius: 5px;
  z-index: -1;
  transition: all 200ms ease;
}

.about-right {
  width: 50%;
  z-index: 3;
}

.about-right img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 3/3;
}

.about_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: bottom right;
  transform: rotate(-8deg);
  background: #000000;
  z-index: 2;
}

.about_background::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 150%;
  height: 100%;
  background: #000000;
  content: "";
}

.fill_height {
  height: 100%;
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.about_image {
  position: absolute;
  right: 0%;
  /* transform: translateX(-50%); */
  bottom: 0;
}
@media (min-width: 1200px) {
  /* .about-main {
    max-width: 1140px;
  } */

  .container {
    max-width: 1170px;
  }
}

@media only screen and (max-width: 990px) {
  /* .about-main {
    max-width: 720px;
  } */

  .about-left {
    margin-left: 5%;
  }
}
@media only screen and (max-width: 680px) {
  .about-main {
    display: block;
    padding: 3rem 0;
    z-index: 3;
    position: relative;
  }

  .about-gym {
    position: absolute;
    rotate: 90deg;
    right: 34%;
    top: 10%;
    color: #fffbee1f;
    font-size: 250px;
    font-weight: 900;
    z-index: 3;
  }

  .about-left,
  .about-right {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .about-right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-right img {
    width: 100%;
    object-fit: cover;
  }

  .about-main {
    max-width: 520px;
  }

  .about_image img {
    width: 100%;
  }

  .about-button a {
    width: 100%;
    margin-bottom: 50px;
  }
  .about-button a p {
    width: 100%;
  }
}
